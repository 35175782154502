
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import RestTable from '@/components/table/RestTable.vue';

export default defineComponent({
  name: 'RewardPolicy',
  components: { RestTable },
  setup() {
    const { https, mapper } = useInject();

    const tableOpts = {
      search: {
        fetch: true,
        remote: {
          action: https?.market.listExtraPolicy
        }
      },
      cols: [
        {
          field: 'id',
          label: 'ID',
          width: 50
        },
        {
          field: 'extraCode',
          label: '活动编号',
          width: 100
        },
        {
          field: 'extraDesc',
          label: '活动描述',
          width: 240
        },
        {
          field: 'reachAmt',
          label: '达标金额'
        },
        {
          field: 'rewardAmt',
          label: '返现金额'
        },
        {
          field: 'backPerson',
          label: '返现对象',
          options: mapper?.market.backPersonTypes,
        },
        {
          field: 'beginTerm',
          label: '开始周期'
        },
        {
          field: 'endTerm',
          label: '结束周期'
        },
        {
          field: 'termUnit',
          label: '周期单位',
          options: mapper?.market.termUnitTypes
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.common.enableBool
        },
        {
          field: 'crtTime',
          label: '创建时间',
          width: 160
        }
      ],
      schema: {
        content: {
          cols: [
            {
              field: 'id',
              visible: false
            },
            {
              field: 'extraCode',
              label: '活动编号',
              required: true
            },
            {
              field: 'extraDesc',
              label: '活动描述',
              required: true
            },
            {
              field: 'reachAmt',
              label: '达标金额',
              type: 'number',
              scale: 2,
              required: true
            },
            {
              field: 'rewardAmt',
              label: '返现金额',
              type: 'number',
              scale: 2,
              required: true
            },
            {
              field: 'backPerson',
              label: '返现对象',
              type: 'select',
              options: mapper?.market.backPersonTypes,
              required: true
            },
            {
              field: 'beginTerm',
              label: '开始周期',
              type: 'number',
              required: true
            },
            {
              field: 'endTerm',
              label: '结束周期',
              type: 'number',
              required: true
            },
            {
              field: 'termUnit',
              label: '周期单位',
              type: 'select',
              options: mapper?.market.termUnitTypes,
              required: true
            },
            {
              field: 'status',
              label: '状态',
              type: 'switch',
              options: mapper?.common.enableBool,
              required: true
            }
          ]
        }
      },
      builtinCmd: {
        create: { action: https?.market.editExtraPolicy },
        update: { action: https?.market.editExtraPolicy }
      }
    };
    return { tableOpts };
  }
});
